.Articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  font-family: "Arimo", sans-serif;
  .Article {
    &__image {
      width: 100%;
      height: 300px;
      img {
        height: 100%;
      }
    }
    &__title {
      color: #020b99;
      font-weight: bold;
      padding: 10px 0;
      line-height: 1.2em;
    }
    &__content {
      color: #363963;
      line-height: 1.2em;
    }
  }
}
@media screen and (max-width: 1200px) {
  .Articles {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 720px) {
  .Articles {
    grid-template-columns: repeat(1, 1fr);
  }
}
