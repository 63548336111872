//Cicle Select
//Выберите год 
//Направленнность программы
//Форма обучения


.CircleSelect {
  position: relative;
  left: 0;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    height: 35px;//внутренняя ширина иконок
    border: 2px solid #7b97f8;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: #fff;
    border-radius: 25px;
    cursor: pointer;
    z-index: 4;
  }
  &__selected-item {
    padding-left: 20px;
    font-size: 12px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    text-transform: uppercase;
  }
  &__arrow {
    position: absolute;
    content: '';
    background: url('../../../assets/images/png/form-arrow.png');
    width: 34px;
    height: 34px;
    top: 50%;
    transform: translateY(-50%);
    right: -2px;
    background-repeat: no-repeat;
    border: 2px solid #7b97f8;
    border-radius: 50%;
    padding: 10px;
    background-position: center;
    transition: 300ms;
  }
  //Форма обучения бургер
  &__items {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 30px 0px;
    padding-top: 64px;
    padding-right: 0;
    background: #fff;
    border: 2px solid #7b97f8;
    border-top: none;
    border-radius: 25px;
    font-size: 14px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 3;
    //transform: translateY(-5px); 
    & > div {
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .selected {
    color: #000b93;
  }
}


.Form_of_promise {
  position: relative;
  left: 0;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    height: 35px;//внутренняя ширина иконок
    border: 2px solid #7b97f8;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: #fff;
    border-radius: 25px;
    transform: translateY(-5px);
    cursor: pointer;
    z-index: 4;
  }
  &__selected-item {
    padding-left: 20px;
    font-size: 12px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    text-transform: uppercase;
  }
  &__arrow {
    position: absolute;
    content: '';
    background: url('../../../assets/images/png/form-arrow.png');
    width: 34px;
    height: 34px;
    top: 50%;
    transform: translateY(-50%);
    right: -2px;
    background-repeat: no-repeat;
    border: 2px solid #7b97f8;
    border-radius: 50%;
    padding: 10px;
    background-position: center;
    transition: 300ms;
  }
  //Форма обучения бургер
  &__items {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 30px 0px;
    padding-top: 64px;
    padding-right: 0;
    background: #fff;
    border: 2px solid #7b97f8;
    border-top: none;
    border-radius: 25px;
    font-size: 14px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 3;
    transform: translateY(-5px); 
    & > div {
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .selected {
    color: #000b93;
  }
}

//Region Select
//Все Регионы
.RegionSelect {
  position: relative;
  left: 0;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    height: 35px;
    border: 2px solid #7b97f8;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: #fff;
    border-radius: 25px;
    cursor: pointer;
    z-index: 4;
  }
  &__selected-item {
    padding-left: 20px;
    font-size: 12px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    text-transform: uppercase;
  }
  &__arrow {
    position: absolute;
    content: '';
    background: url('../../../assets/images/svg/map.svg');
    width: 10px;
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    background-repeat: no-repeat;
    padding: 10.1px;
    background-position: center;
    transition: 300ms;
  }
  //Не изветсно
  &__items {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 400px;
    overflow-y: auto;
    padding: 30px 0px;
    padding-top: 64px;
    padding-right: 0;
    background: #fff;
    border: 2px solid #7b97f8;
    border-top: none;
    border-radius: 25px;
    font-size: 14px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 3;
    & > div {
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .selected {
    color: #000b93;
  }
}

//Alphabet Select
//Не изветсно
.AlphabetSelect {
  position: absolute;
  left: 150px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  @media screen and (max-width: 720px) {
    z-index: 4;
  }
  &__items {
    position: absolute;
    width: 187px;
    left: 0;
    padding: 20px;
    padding-right: 0;
    background: #fff;
    border: 2px solid #7b97f8;
    font-size: 14px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 3;
    & > div {
      margin-bottom: 10px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .selected {
    color: #000b93;
  }
}

//Input Select
.InputSelect {
  position: relative;
  left: 0;
  transition: width 300ms;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    height: 39px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: #000b93;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    cursor: pointer;
    z-index: 14;
  }
  &__selected-item {
    padding-left: 58px;
    font-size: 12px;
    color: #fff;
    font-family: 'Arimo';
    font-weight: 700;
    text-transform: uppercase;
  }
  &__arrow {
    position: absolute;
    content: '';
    background: url('../../../assets/images/svg/burger-yellow.svg');
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    background-repeat: no-repeat;
    padding: 10.1px;
    background-position: center;
    transition: 250ms;
  }
  //Все регионы 
  &__items {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 30px 0px;
    padding-top: 64px;
    padding-right: 0;
    background: #fff;
    border-top-left-radius: 25px;
    background: #000b93;
    font-size: 14px;
    color: #fff;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 13;
    opacity: 0;
    transition: 333ms;
    & > div {
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.change-height {
      opacity: 1;
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 10px solid #000b93;
    border-bottom: 20px solid transparent;
    right: -10px;
    top: 0;
  }
  .selected {
    color: #ff8500;
  }
}

//Header Select
.HeaderSelect {
  position: relative;
  left: 0;
  transition: width 300ms;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    height: 57px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: #000b93; 
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    cursor: pointer;
    z-index: 6;
  }
  &__selected-item {
    padding-left: 58px;
    padding-top: 3px;
    font-size: 12px;
    color: #fff;
    font-family: 'Arimo';
    font-weight: 700;
    text-transform: uppercase;
  }
  &__arrow {
    position: absolute;
    content: '';
    background: url('../../../assets/images/svg/burger-yellow.svg');
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    background-repeat: no-repeat;
    padding: 10.1px;
    background-position: center;
    transition: 250ms;
  }
  //Не изветсно 
  &__items {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 30px 0px;
    padding-top: 64px;
    padding-right: 0;
    background: #0077ff;
    border-top-left-radius: 25px;
    background: #000b93;
    font-size: 14px;
    color: #fff;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 5;
    opacity: 0;
    transition: 333ms;
    & > div {
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.change-height {
      opacity: 1;
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 28.5px solid transparent;
    border-left: 10px solid #000b93;
    border-bottom: 28.5px solid transparent;
    right: -10px;
    top: 0;
  }
  .selected {
    color: #ff8500;
  }
}

//Region Select Map
.RegionSelect-map {
  position: relative;
  left: 0;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    height: 35px;
    border: 2px solid #ff9f29;
    width: 440px;
    margin: 0px;
    padding: 0px;
    background: #fff;
    border-radius: 25px;
    cursor: pointer;
    z-index: 4;
    @media screen and (max-width: 720px) {
      width: 100%;
    }
  }
  &__selected-item {
    padding-left: 20px;
    font-size: 12px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    text-transform: uppercase;
  }
  &__arrow {
    position: absolute;
    content: '';
    background: url('../../../assets/images/svg/map.svg');
    width: 10px;
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    background-repeat: no-repeat;
    padding: 10.1px;
    background-position: center;
    transition: 300ms;
  }
  &__items {
    position: absolute;
    width: 440px;
    top: 0;
    left: 0;
    height: 400px;
    overflow-y: auto;
    padding: 30px 0px;
    padding-top: 64px;
    padding-right: 0;
    background: #fff;
    border: 2px solid #7b97f8;
    border-top: none;
    border-radius: 25px;
    font-size: 14px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 3;
    & > div {
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .selected {
    color: #000b93;
  }
}

.default-regions {
  position: relative;
  left: 0;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1px 0;
    height: 35px;
    border: 1px solid #dbdbed;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: #fff;
    cursor: pointer;
    z-index: 4;
    border-radius: 7px;
  }
  &__selected-item {
    padding-left: 10px;
    font-size: 12px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    text-transform: uppercase;
  }
  &__arrow {
    position: absolute;
    content: '';
    background: url('../../../assets/images/svg/map.svg');
    width: 10px;
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    background-repeat: no-repeat;
    padding: 10.1px;
    background-position: center;
    transition: 300ms;
  }
  &__items {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 400px;
    overflow-y: auto;
    padding: 30px 0px;
    padding-top: 64px;
    padding-right: 0;
    background: #fff;
    border: 1px solid #dbdbed;
    border-top: none;
    font-size: 14px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 3;
    border-radius: 5px;
    & > div {
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .selected {
    color: #000b93;
  }
}


.default-org {
  position: relative;
  left: 0;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1px 0;
    height: 35px;
    border: 1px solid #dbdbed;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: #fff;
    cursor: pointer;
    z-index: 4;
    border-radius: 7px;
  }
  &__selected-item {
    padding-left: 10px;
    font-size: 12px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    text-transform: uppercase;
  }
  &__arrow {
    position: absolute;
    content: '';
    background: url('../../../assets/images/png/form-arrow.png');
    width: 10px;
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    background-repeat: no-repeat;
    padding: 10.1px;
    background-position: center;
    transition: 300ms;
  }
  &__items {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 400px;
    overflow-y: auto;
    padding: 30px 0px;
    padding-top: 64px;
    padding-right: 0;
    background: #fff;
    border: 1px solid #dbdbed;
    border-top: none;
    font-size: 14px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 700;
    z-index: 3;
    border-radius: 5px;
    & > div {
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .selected {
    color: #000b93;
  }
}


// .My_name {
//   transform: translateY(-5px); 
// }


.Term_of_study_and_hours{
  transform: translateY(-5px); 
}