.collapsible-block {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.collapsible-arrow {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  transition: 500ms;
}

.active-arrow {
    transform: rotate(-180deg);
}