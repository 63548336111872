.BankPage {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 87px;
  padding-bottom: 200px;
  z-index: 4;
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
    padding-top: 35px;
  }
  &__breadcrumbs {
    position: relative;
    display: grid;
    grid-template-columns: 9.3% 1fr 9.3%;
    max-width: 1400px;
    margin: 0 auto;
    z-index: 4;
    @media screen and (max-width: 720px) {
      grid-template-columns: 4% 1fr 4%;
    }
    &__main-link {
      font-size: 14px;
      color: #b4b4d1;
      font-family: 'Arimo';
      font-weight: 400;
    }
    &__second-link {
      display: inline-block;
      font-size: 14px;
      color: #75758d;
      font-family: 'Arimo';
      font-weight: 400;
    }
    &__slash {
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      border-right: 1px solid #b4b4d1;
      height: 10px;
      transform: rotate(25deg);
    }
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #f5f5f5;
    @media screen and (max-width: 720px) {
      padding-top: 310px;
    }
  }
  &__title {
    margin-bottom: 37px;
    font-size: 52px;
    color: #314190;
    font-family: 'Montserrat';
    font-weight: 700;
    @media screen and (max-width: 720px) {
      margin-bottom: 41px;
    }
    @media screen and (max-width: 670px) {
      font-size: 40px !important;
      line-height: 43px;
    }
    @media screen and (max-width: 425px) {
      font-size: 26px !important;
      line-height: 36px;
    }
  }
  &__under-title {
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
    color: #314190;
    font-family: 'Arimo';
    font-weight: 400;
    border-top: 2px solid #dcdbff;
    border-bottom: 2px solid #dcdbff;
  }
}

.BankCard {
  margin-bottom: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 35px;
  padding-right: 35px;
  background-color: #fff;
  transition: 300ms;
  border-radius: 30px;
  @media screen and (max-width: 720px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &:hover {
    box-shadow: 0 0 6px rgba(3, 4, 4, 0.41);
  }
  &__title {
    position: relative;
    margin-left: 29px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #314190;
    font-family: 'Arimo';
    font-weight: 700;
    cursor: pointer;
    &:before {
      position: absolute;
      content: url('../../assets/images/svg/doc.svg');
      width: 18px;
      height: 18px;
      left: -29px;
      top: 0;
    }
  }
  &__title-span {
    color: #314190;
    &:hover {
      text-decoration: underline !important;
    }
  }
  &__lower-container {
    display: grid;
    grid-template-columns: 160px 1fr 142px 142px 140px;
    grid-gap: 45px;
    margin-top: 16px;
    align-items: center;
    @media screen and (max-width: 720px) {
      display: block;
    }
  }
  &__name {
    position: relative;
    margin-left: 25px;
    font-size: 13px;
    line-height: 18px;
    color: #8d8db9;
    font-family: 'Arimo';
    font-weight: 400;
    @media screen and (max-width: 720px) {
      margin-bottom: 10px;
    }
    &:before {
      position: absolute;
      content: url('../../assets/images/svg/user-new.svg');
      width: 20px;
      height: 20px;
      left: -25px;
      top: -2px;
    }
  }
  &__place {
    font-size: 13px;
    line-height: 18px;
    color: #8d8db9;
    font-family: 'Arimo';
    font-weight: 400;
    @media screen and (max-width: 720px) {
      margin-bottom: 10px;
      margin-left: 27px;
    }
  }
  &__publishDate {
    font-size: 13px;
    line-height: 18px;
    color: #8d8db9;
    font-family: 'Arimo';
    font-weight: 400;
  }
  &__review {
    position: relative;
    cursor: pointer;
    @media screen and (max-width: 720px) {
      margin-bottom: 10px;
      margin-left: 27px;
    }
    &:before {
      position: absolute;
      content: url('../../assets/images/svg/arrow.svg');
      width: 18px;
      height: 18px;
      left: -25px;
      top: -4px;
    }
    & a {
      font-size: 13px;
      color: #314190;
      font-family: 'Arimo';
      font-weight: 400;
      text-decoration: underline !important;
    }
  }
  &__download-text {
    display: block;
    margin-top: 7px;
    font-size: 13px;
    color: #8d8db9;
    font-family: 'Arimo';
    font-weight: 400;
    @media screen and (max-width: 720px) {
      margin-right: 10px;
    }
  }
  &__download-wrapper {
    text-align: right;
    @media screen and (max-width: 720px) {
      display: flex;
      justify-content: center;
    }
  }
  &__pdf {
    &__link {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
  &__doc {
    &__link {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
}

.smaller-title {
  font-size: 35px;
}
