.Error {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: #2f2a4c;
  padding: 100px 0;
  &__big {
    font-size: 33px;
  }
  &__small {
    font-size: 20px;
  }
}
