.LoadingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  // background:;
}
.Ellipse1 {
  position: relative;
  z-index: 1;
  right: -100px;
}
.Ellipse2 {
  position: relative;
  bottom: 60px;
  left: 215px;
}
.Reactangle1 {
  position: relative;
  left: 135px;
  top: 110px;
}
.Reactangle2 {
  position: relative;
  bottom: 75px;
  left: 50px;
}
.Reactangle3 {
  position: relative;
  right: 345px;
  top: 65px;
}
