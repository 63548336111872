.modal-overlay {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(71, 87, 118, 0.89);
  z-index: 2000;

  backdrop-filter: blur(10px);
}

.modal-wrapper {
  width: 100%;

  height: 100%;
  z-index: 2000;
  @media screen and (max-width: 768px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.modal-wrapper-single {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 3000;
  animation: 0.433s ease 0s 1 normal none running fadein;
  & ~ .modal {
    width: 80%;
    height: 80%;
    margin: 0 auto;
  }
}

.form-container {
  height: auto !important;
}

.modal-login {
  right: 35%;
  position: absolute;
  top: 90px;
  z-index: 5000;
  @media screen and (max-width: 768px) {
    right: auto;
  position: relative;
  top: auto;
  }
}

.modal-registration {
  right: 35%; //9.2%;
  position: absolute;
  top: 80px;
  z-index: 5000;
  @media screen and (max-width: 768px) {
    right: auto;
  position: relative;
  top: auto;
  }
}

.iframe-modal {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  left: 50%;
  position: fixed;
  top: 10vh;
  transform: translateX(-50%);
  z-index: 5000;
  animation: 0.433s ease 0s 1 normal none running fadein;
}
