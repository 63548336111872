.InformationPage {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 87px;
  padding-bottom: 200px;
  z-index: 4;
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
    padding-top: 35px;
  }
  &__breadcrumbs {
    position: relative;
    display: grid;
    grid-template-columns: 9.3% 1fr 9.3%;
    max-width: 1400px;
    margin: 0 auto;
    z-index: 4;
    @media screen and (max-width: 720px) {
      grid-template-columns: 4% 1fr 4%;
    }
    &__main-link {
      font-size: 14px;
      color: #b4b4d1;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__second-link {
      display: inline-block;
      font-size: 14px;
      color: #75758d;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__slash {
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      border-right: 1px solid #b4b4d1;
      height: 10px;
      transform: rotate(25deg);
    }
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #f5f5f5;
    @media screen and (max-width: 720px) {
      padding-top: 310px;
    }
  }
  &__title {
    margin-bottom: 37px;
    font-size: 52px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
    @media screen and (max-width: 720px) {
      margin-bottom: 41px;
    }
    @media screen and (max-width: 670px) {
      font-size: 40px;
      line-height: 43px;
    }
    @media screen and (max-width: 425px) {
      font-size: 26px;
      line-height: 36px;
    }
  }
  &__category-name {
    margin-bottom: 30px;
    font-size: 25px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
  }
  &__list-collapse-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: -16px;
    margin-bottom: 40px;
    padding-right: 35px;
    font-size: 12px;
    line-height: 60px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #b4b4d1;
    & span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.InformationCard {
  margin-bottom: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 35px;
  padding-right: 35px;
  background-color: #fff;
  transition: 300ms;
  border-radius: 30px;
  &:hover {
    box-shadow: 0 0 6px rgba(3, 4, 4, 0.41);
  }
  &__title {
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 700;
  }
  &__lower-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 720px) {
      justify-content: space-around;
    }
  }
  &__review {
    display: flex;
    align-items: center;
    margin-right: 33px;
    font-size: 13px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    &__eye {
      width: 26px;
      height: 26px;
      margin-right: 5px;
    }
  }
  &__download-text {
    margin-right: 10px;
    font-size: 13px;
    color: #8d8db9;
    font-family: "Arimo";
    font-weight: 400;
  }
  &__download-wrapper {
    display: flex;
    align-items: center;
  }
}

.custom-pdf-link {
  margin-right: 7px;
  width: 32px !important;
}
