.form-container-registration {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #314190;
  .form-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 680px;
    padding: 45px;
    background-color: #ffffff;
    border-radius: 30px;
  }
  .form-title {
    position: relative;
    margin-bottom: 26px;
    font-size: 20px;
    line-height: 18px;
    color: #000b93;
    font-weight: 700;
    text-transform: uppercase;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .form-description {
    font-size: 15px;
    color: #000b93;
    text-align: center;
    margin-bottom: 15px;
    text-indent: 15px;
    line-height: normal;
  }
  .form-message {
    font-size: 15px;
    color: red;
    text-align: center;
    margin-bottom: 15px;
    text-indent: 15px;
    line-height: normal;
  }

  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    border: 1px solid #ccc;
    border-radius: 20px;
    display: inline-block;
    padding: 10px 12px;
    cursor: pointer;
    background-color: #68cf8c;
    color: #fff;
  }

  .form-label {
    margin-bottom: 9px;
    font-size: 16px;
    color: #2e3f95;
    font-family: "Arimo";
    font-weight: 400;
  }
  .form-input {
    display: block;
    width: 100%;
    padding: 9px 0;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    text-indent: 2%;

    background: #ffffff;
    border: 3px solid #7b97f8;
    border-radius: 10px;
  }
  .default-org {
    background: #ffffff;
    border: 3px solid #7b97f8;
    border-radius: 10px;
  }
  .default-regions {
    background: #ffffff;
    border: 3px solid #7b97f8;
    border-radius: 10px;
  }
  .form-file-input-box {
    display: flex;
    margin-top: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .form-file-input-filename {
    font-size: 16px;
    color: #4c579a;
    font-weight: 400;
    max-width: calc(100% - 70px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .checkbox:not(checked) {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
  }
  .form-label-checkbox {
    position: relative;
    padding: 0 0 0 60px;
    cursor: pointer;
  }
  .lower-container-registration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    .forget-pass {
      font-size: 14px;
      color: #4c579a;
      font-family: "Arimo";
      font-weight: 400;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #0817d0;
      }
    }
  }
  .submit-container {
    position: relative;
    .submit-icon {
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      left: 20px;
      top: 8px;
      z-index: 10;
    }
    .submit-registration {
      position: relative;
      display: block;
      padding: 10px 20px 10px 20px;
      font-size: 16px;
      font-weight: 600;
      font-family: Montserrat;
      line-height: 19px;
      color: #fff;
      background-color: #68cf8c;
      border: none;
      border-radius: 30px;
      outline: none;
      transition: 0.3s;
      box-shadow: 0px 6px 9px 1px #7b97f8;

      &:hover {
        background-color: #4c579a;
      }
      &:active {
        box-shadow: none;
      }
    }
  }
}

.input-container {
  position: relative;
  margin-bottom: 22px;
}

.is-danger {
  border: 2px solid #faa86c !important;
}

.form-error {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: #fe8e15;
  font-family: "Arimo";
  font-weight: 400;
}

select.form-input {
  position: relative;
  appearance: none;
  background: #ffffff;
  border: 3px solid #7b97f8;
  border-radius: 10px;
}

.input-container.select {
  &:after {
    position: absolute;
    content: url("../../assets/images/png/form-arrow.png");
    right: 15px;
    top: 31px;
  }
  option {
    padding: 15px;
  }
}

.modal-confirm {
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 5000;
  outline: none;
  .confirm-label {
    margin-bottom: 9px;
    font-size: 16px;
    color: #4c579a;
    font-family: "Arimo";
    font-weight: 400;
    text-align: center;
  }
  .confirm-btn {
    position: relative;
    display: block;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    font-family: "Arimo";
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #0817d0;
    border: 1px solid #0817d0;
    border-radius: 25px;
    outline: none;
    transition: 0.3s;
    &:hover {
      background-color: #4c579a;
      border: 1px solid #4c579a;
    }
  }
}

.phone-mask {
  margin-bottom: 9px;
  font-size: 13px;
  color: #4c579a;
  font-family: "Arimo";
  font-weight: 400;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0 !important;
  vertical-align: top;
  margin-left: 25px;
  &:before {
    position: absolute;
    top: -2px;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 2px;
  }
  &:after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
  }
}

input[type="checkbox"]:checked + .custom-control-label:after {
  position: absolute;
  top: 2px;
  left: -19px;
  content: "";
  background-size: cover;
  width: 10px;
  height: 5px;
  border-left: 1px solid green;
  border-bottom: 1px solid green;
  transform: rotate(-45deg);
}

.disable-btn {
  cursor: default !important;
  box-shadow: none !important;
  &:hover {
    background-color: #68cf8c !important;
  }
}

.agree-link {
  color: #4c579a;
  text-decoration: underline !important;
}
