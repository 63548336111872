.SeminarPage {
  &__content {
    &__last-block {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 40px;
      margin-left: -30px;
      margin-right: -30px;
      border-top: 2px solid #dcdbff;
      border-bottom: 2px solid #dcdbff;
      padding: 30px 0 10px 0;
      &__item {
        width: 260px;
        margin-right: 40px;
        margin-bottom: 20px;
        &__first-row {
          display: block;
          font-size: 16px;
          line-height: 18px;
          color: #8d8db9;
          font-weight: 400;
          font-family: "Arimo";
        }
        &__second-row {
          font-size: 16px;
          line-height: 22px;
          color: #363963;
          font-family: "Arimo";
          font-weight: 400;
        }
        &:last-child {
          margin: 0 0 20px 0;
        }
      }
    }
    &__link {
      display: flex;
      justify-content: center;
      margin-top: 70px;
    }
    &__media {
      margin-top: 75px;
      border-bottom: 2px solid #dcdbff;
      &_window {
        display: flex;
        justify-content: center;
        transform: translateY(10%);
      }
    }
    &__docs {
      display: block;
      margin-top: 15px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      p {
        color: #8d8db9;
      }
      &_link {
        color: #363963;
      }
      & > a {
        margin-left: 20px;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: -10px;
        }
      }
    }
  }
}
.Button {
  cursor: pointer;
  background: #00148d;
  display: flex;
  border-radius: 40px;
  padding: 15px;
  &__image {
    width: 30px;
  }
  &__text {
    font-family: "Arimo", sans-serif;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    padding-left: 13px;
  }
  &:hover {
    opacity: 0.8;
  }
}
@media screen and (max-width: 720px) {
  .SeminarPage {
    &__content {
      &__last-block {
        margin-left: unset;
        margin-right: unset;
      }
    }
  }
}
