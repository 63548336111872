.Slider {
  position: relative;
  display: flex;
  align-items: center;
  height: 415px;
  .arrow {
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: #0a2a71;
    transform: translate(-50%, -50%) rotate(45deg);
    &:hover {
      background-color: #2d509e;
    }
  }
  .arrow-prev {
    left: -15px;
  }
  .arrow-next {
    right: -35px;
  }
  .arrow-mask {
    width: 100%;
    height: 100%;
    background-color: #f5f5fe;
    position: absolute;

    right: 0;
    bottom: 0;
    animation: paint 10s ease-in-out infinite,
    flip 10s ease-in-out infinite;
    &--prev {
      top: -15%;
      left: 15%;
    }
    &--next {
      right: 15%;
      top: 15%;
    }
  }
}
