.ErrorPage {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 87px;
  padding-bottom: 200px;
  z-index: 4;
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
  }
  &__breadcrumbs {
    position: relative;
    display: grid;
    grid-template-columns: 9.3% 1fr 9.3%;
    max-width: 1400px;
    margin: 0 auto;
    z-index: 4;
    @media screen and (max-width: 720px) {
      grid-template-columns: 4% 1fr 4%;
    }
    &__main-link {
      font-size: 14px;
      color: #b4b4d1;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__second-link {
      display: inline-block;
      font-size: 14px;
      color: #75758d;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__slash {
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      border-right: 1px solid #b4b4d1;
      height: 10px;
      transform: rotate(25deg);
    }
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #fff;
  }
  &__content {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    img {
      margin-left: -15px;
      margin-bottom: 15px;
    }
    div {
      font-size: 18px;
      color: #000b93;
      font-family: "Montserrat";
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
