.Card {
  font-family: Arimo;
  font-weight: bold;
  font-size: 16px;
  width: 375px;
  max-width: 375px;
  height: auto;
  display: grid;
  gap: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &__block-container {
    min-height: 45px;
  }
  &__image-container {
    min-height: 400px;
    display: flex;
    align-items: center;
  }
  &__image {
    // min-height: auto;
    // max-height: 350px;
  }
  &__title {
    position: relative;
    color: #000b93;
    line-height: 17px;
  }
  &__content {
    font-weight: 400;
    line-height: 17px;
    color: #363963;
    // align-self:start;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__data {
    color: #000b93;
  }
  &__info-container {
    grid-column-start: 2;
    grid-row-start: 2;
    display: grid;
    grid-template-columns: 1fr 40%;
  }
  &__text {
    & > p {
      position: relative;
      margin: 0;
      margin-left: 18px;
      margin-bottom: 25px;
      font-size: 18px;
      line-height: 20px;
      color: #314190;
      font-family: 'Arimo';
      font-weight: 400;
    }
  }
}
