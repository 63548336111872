.Footer {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  grid-row-gap: 90px;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 45px;
  z-index: 3;
  @media screen and (max-width: 670px) {
    grid-template-columns: 4% 1fr 4%;
    margin-top: 60px;
  }
  &__container {
    position: relative;
    margin: 0 auto;
    @media screen and (max-width: 1653px) {
      margin-top: 30px;
    }
  }
  &__background-image {
    background: #314190;
    background-repeat: no-repeat;
    position: absolute;
    object-fit: none;
    object-position: center;
    height: 100%;
    width: 100%;
    background-position-x: center;
  }
  &__upper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-style: normal;
    font-weight: 500;
    font-family: 'Montserrat';
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 80px 1fr 1fr;
    }
    @media screen and (max-width: 670px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
    &__left-icon {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      place-items: center;
      justify-self: center;
      font-weight: 400;
      color: #ffffff;
      @media screen and (max-width: 1024px) {
        justify-self: right;
      }
      @media screen and (max-width: 670px) {
        justify-self: center;
      }
      @media screen and (max-width: 670px) {
        grid-row-start: 2;
        margin-left: -70px;
        justify-self: center;
      }
      @media screen and (max-width: 320px) {
        margin-left: 0;
        justify-self: start;
      }
      > img {
        width: 115px;
      }
      &__text {
        width: 255px;
        text-align: center;
      }
    }
    &__center-icon {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-self: center;
      width: 240px;

      align-content: space-evenly;
      padding-top: 15px;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      @media screen and (max-width: 670px) {
        grid-template-columns: 78px 260px;
        justify-self: center;
        width: auto;
      }
      @media screen and (max-width: 367px) {
        display: block;
      }
      &__phone {
        color: #ffffff;
      }
      &__item {
        color: #ffffff;
      }
    }
    &__right-icon {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      place-items: center;
      justify-self: center;

      // gap: 10px;
      font-size: 12px;
      width: 300px;
      color: #ffffff;
      @media screen and (max-width: 670px) {
        grid-row-start: 3;
        margin-left: -28px;
        justify-self: center;
      }
      @media screen and (max-width: 320px) {
        margin-left: 0;
        justify-self: start;
      }
      &__text {
        text-align: center;
        justify-self: center;
        width: 200px;
      }
      > img {
        width: 85px;
        justify-self: center;
      }
    }
  }
  &__lower {
    display: grid;
    grid-template-columns: 100% 1fr 1fr;
    grid-row-start: 2;
    grid-column-start: 2;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 112.9%;
    @media screen and (max-width: 1024px) {
      grid-gap: 23px;
    }
    @media screen and (max-width: 670px) {
      display: block;
    }
    &__copyrigth {
      justify-self: center;
      font-size: 11px;
      color: #ffffff;
      font-weight: 400;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        grid-row-start: 2;
        grid-column-end: 4;
      }
      @media screen and (max-width: 670px) {
        text-align: center;
      }
    }
  }
  &__commit {
    display: grid;
    grid-template-columns: 100% 1fr 1fr;
    grid-row-start: 2;
    grid-column-start: 3;

    justify-self: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-size: 10px;
    color: #304192;
    @media screen and (max-width: 1024px) {
      grid-gap: 20px;
    }
    @media screen and (max-width: 670px) {
      display: block;
    }
  }
}

.email-link {
  color: #ffffff;
  text-decoration: underline !important;
}
