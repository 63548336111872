.SimilarArticles {
  background: #f5f5fe;
  margin-bottom: -30px;
  margin-top: -180px;
  padding-bottom: 250px;
  font-family: "Arimo";
  &__caption {
    padding: 40px 0;
    font-size: 20px;
    text-align: center;
    color: #000b93;
    font-weight: 700;
  }
  &__list {
    display: flex;
    justify-content: center;
  }
  &__item {
    width: 330px;
    //height: 450px;
    margin: 0 10px;
    background: #fff;
  }
  &__img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  &__title {
    padding: 20px;
    color: #000b93;
    font-weight: 500;
    line-height: 1.2em;
  }
  &__sign {
    font-size: 15px;
    color: #363963;
    padding: 20px;
    margin-top: -30px;
  }
}
@media screen and (max-width: 860px) {
  .SimilarArticles {
    &__item {
      width: 250px;
      //height: 450px;
      margin: 0 10px;
      background: #fff;
    }
  }
}
