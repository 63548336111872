.Header-container {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 4;
  .main_title_container {
    display: grid;
    grid-template-columns: 9.3% 1fr 9.3%;
    grid-row-gap: 19px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 4% 1fr 4%;
    }
    .main_title_image_container {
      display: grid;
      grid-template-columns: 165px 0.5fr;
      grid-gap: 19px;
      align-items: flex-end;
      @media screen and (max-width: 585px) {
        grid-template-columns: 69px 1fr 4%;
        grid-gap: 10px;
      }
      .title_image {
        width: 165px;
        height: 200px;
        @media screen and (max-width: 720px) {
          width: 144px;
          height: 175px;
        }
        @media screen and (max-width: 585px) {
          width: 100px;
          height: 129px;
        }
        @media screen and (max-width: 470px) {
          width: 90px;
          height: 119px;
        }
        @media screen and (max-width: 425px) {
          width: 70px;
          height: 90px;
        }
      }
    }
  }
}

.main_title {
  font-size: 50px;
  line-height: 54px;
  color: #fff;
  color: #314190;

  font-family: 'Montserrat';
  font-weight: 700;
  @media screen and (max-width: 720px) {
    font-size: 45px;
    line-height: 48px;
  }
  @media screen and (max-width: 670px) {
    font-size: 40px;
    line-height: 43px;
  }
  @media screen and (max-width: 525px) {
    font-size: 33px;
    line-height: 46px;
  }
  @media screen and (max-width: 470px) {
    font-size: 27px;
    line-height: 30px;
  }
  @media screen and (max-width: 425px) {
    font-size: 22px;
    line-height: 24px;
  }
  &__lower-text {
    grid-column-start: 2;
    grid-column-end: 3;
    width: 300px;
    margin-left: 50px;
    font-size: 20px;
    line-height: 26px;
    color: #314190;
    font-family: 'Arimo';
    font-weight: 400;
    @media screen and (max-width: 720px) {
      width: auto;
      font-size: 18px;
      line-height: 23px;
    }
    @media screen and (max-width: 425px) {
      margin-left: 80px;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.Header__container {
  position: relative;
  margin: 0 auto;
  background: url('../../assets/images/png/content-background.png');
  background-size: cover;
  height: 500px;
}

.About {
  position: relative;
  display: grid;
  grid-template-columns: 50% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 137px;
  padding-bottom: 55px;
  @media screen and (max-width: 745px) {
    grid-template-columns: 50% 1fr 4%;
  }
  @media screen and (max-width: 720px) {
    padding-top: 187px;
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: 4% 1fr 4%;
  }
  @media screen and (max-width: 425px) {
    padding-top: 140px;
  }
  @media screen and (max-width: 375px) {
    padding-top: 130px;
  }
  @media screen and (max-width: 320px) {
    padding-top: 80px;
  }
  &__container {
    position: relative;
    margin: 0 auto;
    margin-top: 117px;
    @media screen and (max-width: 1024px) {
      margin-top: 200px;
    }
    @media screen and (max-width: 880px) {
      margin-top: 210px;
    }
  }
  &__background-image {
    position: absolute;
    top: -10px;
    left: 30px;
    object-fit: none;
    object-position: center;
    z-index: -1;
    @media screen and (max-width: 1182px) {
      left: -25px;
    }
    @media screen and (max-width: 1075px) {
      left: -80px;
    }
    @media screen and (max-width: 970px) {
      left: -135px;
    }
    @media screen and (max-width: 860px) {
      left: -190px;
    }
    @media screen and (max-width: 745px) {
      left: -205px;
    }
    @media screen and (max-width: 720px) {
      left: -160px;
      top: 37px;
    }
    @media screen and (max-width: 720px) {
      left: -208px;
    }
    @media screen and (max-width: 650px) {
      display: none;
    }
  }
  &__background-image_man {
    position: absolute;
    top: 289px;
    left: 20%;
    object-fit: none;
    object-position: center;
    z-index: 1;
    @media screen and (max-width: 1182px) {
      left: 16%;
    }
    @media screen and (max-width: 970px) {
      left: 11%;
    }
    @media screen and (max-width: 860px) {
      left: 6%;
    }
    @media screen and (max-width: 720px) {
      left: 11%;
      top: 340px;
    }
    @media screen and (max-width: 650px) {
      display: none;
    }
  }
  &__grid {
    display: grid;
    grid-gap: 35px;
  }
  &__title {
    font-size: 54px;
    color: #000b93;
    font-family: 'Montserrat';
    font-weight: 700;
    @media screen and (max-width: 670px) {
      font-size: 40px;
      line-height: 43px;
    }
    @media screen and (max-width: 425px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
  &__p {
    margin-top: -22px;
    font-size: 16px;
    line-height: 24px;
    color: #363963;
    font-family: 'Arimo';
    font-weight: 400;
    @media screen and (max-width: 425px) {
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.detail-btn {
  margin-top: -10px;
  padding: 8px 30px;
  font-size: 16px;
  line-height: 18px;
  color: #43466d;
  font-family: 'Arimo';
  font-weight: 700;
  background: transparent;
  border-radius: 25px;
  outline: 0;
  border: 2px solid #dbdbed;
  cursor: pointer;
}
