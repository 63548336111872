.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .form-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px;
    background-color: #ededfd;
    min-width: 260px;
    max-width: 600px;
  }
  .form-title {
    position: relative;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 18px;
    color: #000b93;
    font-weight: 700;
    text-transform: uppercase;
  }
  .form-title-under {
    margin-bottom: 26px;
    font-size: 20px;
    line-height: 20px;
    color: #4962da;
    font-family: "Arimo";
    font-weight: 400;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .form-label {
    margin-bottom: 9px;
    font-size: 16px;
    color: #363963;
    font-weight: 400;
  }
  .form-input {
    display: block;
    width: 100%;
    padding: 9px 0;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    text-indent: 2%;
    border: 1px solid #dbdbed;
    border-radius: 5px;
  }
  .checkbox:not(checked) {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
  }
  .form-label-checkbox {
    position: relative;
    padding: 0 0 0 60px;
    cursor: pointer;
  }
  .lower-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
    .forget-pass {
      font-size: 14px;
      color: #363963;
      font-family: "Arimo";
      font-weight: 400;
      text-decoration: underline !important;
      cursor: pointer;
      &:hover {
        color: #0817d0;
      }
    }
  }
  .submit-container {
    position: relative;
    .submit-icon {
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      left: 20px;
      top: 8px;
      z-index: 10;
    }
    .submit {
      position: relative;
      display: block;
      padding: 10px 23px 10px 56px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      font-family: "Arimo";
      color: #fff;
      background-color: #0817d0;
      border: 1px solid #0817d0;
      border-radius: 25px;
      outline: none;
      transition: 0.3s;
      &:hover {
        background-color: #4c579a;
        border: 1px solid #4c579a;
      }
    }
  }
}

.modal-courses-login {
  left: 50%;
  position: fixed;
  top: 10vh;
  transform: translateX(-50%);
  z-index: 5000;
  animation: 0.433s ease 0s 1 normal none running fadein;
}

.input-container {
  margin-bottom: 22px;
}

.is-danger {
  border: 2px solid #faa86c !important;
}

.form-error {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: #fe8e15;
  font-family: "Arimo";
  font-weight: 400;
}

.login-error {
  text-align: center;
  margin-top: 25px;
  color: red;
}
