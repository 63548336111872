.RegistryPage {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 87px;
  padding-bottom: 150px;
  z-index: 4;
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
    padding-top: 35px;
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #ededfd;
    @media screen and (max-width: 720px) {
      padding-top: 310px;
    }
  }
  &__title {
    margin-bottom: 37px;
    font-size: 52px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
    @media screen and (max-width: 720px) {
      margin-bottom: 41px;
    }
    @media screen and (max-width: 670px) {
      font-size: 40px;
      line-height: 43px;
    }
    @media screen and (max-width: 425px) {
      font-size: 26px;
      line-height: 36px;
    }
  }
  &__upper-filter {
    display: grid;
    grid-template-columns: 59% 1fr;
    grid-gap: 30px;
    align-items: center;
    margin-bottom: 23px;
    @media screen and (max-width: 1024px) {
      display: block;
      margin-bottom: 10px;
    }
  }
  &__search-container {
    position: relative;
    display: grid;
    height: 100%;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 13px;
    color: #ededfd;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }
    &:after {
      position: absolute;
      content: url("../../assets/images/svg/musica-searcher-blue.svg");
      width: 17px;
      right: 9px;
      cursor: pointer;
      top: 53%;
      transform: translateY(-50%);
    }
  }
  .burger_icon {
    margin-top: 9px;
    margin-left: 18px;
    width: 25px;
    fill: #ff8500;
  }
  &__search-select {
    position: relative;
    background: #000b93;
    color: #fff;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    &__input {
      padding-right: 45px;
      font-size: 13px;
      color: #363963;
      font-family: "Arimo";
      font-weight: 700;
      text-indent: 3%;
      text-transform: uppercase;
      border: 2px solid #7b97f8;//Поисковое слово или запрос рамка
      border-radius: 30px;
      outline: none;
      @media screen and (max-width: 1024px) {
        height: 35px;
      }
      &::placeholder {
        color: #363963;
      }
    }
  }
  &__middle-filter-upper {
    display: grid;
    grid-template-columns: 358px 1fr;
    grid-gap: 33px;
    align-items: center;
    margin-bottom: 23px;
    @media screen and (max-width: 1024px) {
      display: block;
      margin-bottom: 10px;
    }
  }
  &__date-container {
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: #000000;
    font-family: "Arimo";
    @media screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }
  }
  &__time-container {
    display: grid;
    grid-template-columns: 105px 1fr 70px;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: #000000;
    font-family: "Arimo";
    &__time-span {
      margin-left: 10px;
    }
  }
  &__date-category {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 11px;
    width: 100%;
  }
  &__middle-filter-lower {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 33px;
    align-items: center;
    margin-bottom: 23px;
    .filterInput {
      margin-bottom: 10px;
    }
    @media screen and (max-width: 1024px) {
      display: block;
      margin-bottom: 10px;
    }
  }
  &__lower-filter {
    display: grid;
    grid-template-columns: 4fr 2fr 250px;
    grid-gap: 33px;
    align-items: center;
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
  &__sort-container {
    position: relative;
    font-size: 14px;
    color: #000000;
    font-family: "Arimo";
    @media screen and (max-width: 1024px) {
      margin-top: 20px;
    }
  }
  &__sort-category {
    margin-left: 59px;
  }
  &__arrow {
    position: absolute;
    content: "";
    background: url("../../assets/images/png/form-arrow.png");
    width: 15px;
    height: 15px;
    top: 50%;
    transform: translateY(-50%);
    left: 98px;
    background-repeat: no-repeat;
    border: 2px solid #7b97f8;
    border-radius: 50%;
    padding: 10.3px;
    background-position: center;
    transition: 300ms;
  }
  &__checkbox-container {
    justify-self: flex-end;
  }
  &__check-btn {
    position: relative;
    padding: 10px 25px;
    margin-left: 10px;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    outline: 0;
    border: 1px solid #68cf8c;
    z-index: 4;
    cursor: pointer;

    background: #68cf8c;
    border-radius: 30px;
    &:hover {
      opacity: 0.8;
      color: #ffffff;
    }
    @media screen and (max-width: 1024px) {
      z-index: 3;
    }
  }
}

.disbl-btn {
  cursor: default;
  background: #bce8cb;
  border: 1px solid #bce8cb;
}

.RegistryPageCard {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 238px;
  margin-bottom: 30px;
  padding: 25px 35px;
  background-color: #fff;
  z-index: 4;
  box-shadow: 0 0 6px rgba(3, 4, 4, 0.11);
  transition: 300ms;
  animation: fadein 0.886s;
  @media screen and (max-width: 1024px) {
    display: block;
    padding: 25px 20px;
    z-index: 3;
  }
  &:hover {
    box-shadow: 0 0 6px rgba(3, 4, 4, 0.41);
  }
  &__left-container {
    padding-left: 29px;
    border-right: 2px solid #dbdbed;
    @media screen and (max-width: 1024px) {
      border: none;
    }
  }
  &__right-container {
    align-self: center;
    padding-left: 47px;
    @media screen and (max-width: 1024px) {
      padding-left: 30px;
    }
  }
  &__category {
    position: relative;
    margin-bottom: 4px;
    font-size: 13px;
    line-height: 18px;
    color: #8d8db9;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    &:before {
      position: absolute;
      content: url("../../assets/images/svg/folder.svg");
      width: 25px;
      height: 25px;
      left: -35px;
      top: -7px;
    }
  }
  &__sub-category {
    position: relative;
    margin-bottom: 15px;
    font-size: 13px;
    line-height: 18px;
    color: #8d8db9;
    font-family: "Arimo";
    font-weight: 400;
    &:before {
      position: absolute;
      content: "";
      width: 10px;
      height: 11px;
      left: -21px;
      top: -3px;
      border-left: 2px solid #8d8db9;
      border-bottom: 2px solid #8d8db9;
    }
  }
  &__title {
    position: relative;
    display: block;
    margin-bottom: 17px;
    font-size: 18px;
    line-height: 24px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 700;
    text-decoration: underline !important;
    cursor: pointer;
    &:before {
      position: absolute;
      content: url("../../assets/images/svg/doc.svg");
      width: 18px;
      height: 18px;
      left: -29px;
      top: 0;
    }
  }
  &__location {
    font-size: 13px;
    color: #8d8db9;
    font-family: "Arimo";
    font-weight: 400;
  }
  &__lower-container {
    display: grid;
    grid-template-columns: 170px 1fr 200px;
    grid-gap: 45px;
    margin-top: 16px;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    &__items {
      font-size: 13px;
      color: #8d8db9;
      font-family: "Arimo";
      font-weight: 400;
      @media screen and (max-width: 1024px) {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 20px;
        }
      }
      .link {
        font-size: 13px;
        color: #000b93;
        font-family: Arimo;
        font-weight: 400;
        text-decoration: underline !important;
        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
  &__programm {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 18px;
    color: #8d8db9;
    font-family: "Arimo";
    font-weight: 400;
  }
  &__view-btn,
  &__download-btn {
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 400;
    cursor: pointer;
  }
  &__view-btn {
    &:before {
      position: absolute;
      content: url("../../assets/images/svg/glaz.svg");
      width: 22px;
      height: 22px;
      left: 0px;
      top: 2px;
    }
  }
  &__download-btn {
    margin-left: 8px;
    &:before {
      position: absolute;
      content: url("../../assets/images/svg/down.svg");
      width: 10px;
      height: 10px;
      left: 11px;
      top: -2px;
    }
  }
  .Collapse-btn {
    position: absolute;
    content: "";
    bottom: -26px;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 14px;
    padding-left: 14px;
    padding-right: 14px;
    background: #fff;
    font-size: 12px;
    line-height: 18px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border: 0.1px solid #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.Collapse-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.CollapseBlockCard {
  position: relative;
  max-width: 465px;
  padding: 16px 28px;
  margin: 0 auto;
  overflow: hidden;
  &:before {
    content: url("../../assets/images/jpg/bg-kurs.jpg");
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.2s ease-in-out;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    .CollapseBlockCard__title {
      color: #fff;
    }
    .CollapseBlockCard__location {
      color: #dbdbed;
    }
    .CollapseBlockCard__desc {
      color: #dbdbed;
    }
    .CollapseBlockCard__btn {
      color: #ff9b26;
    }
  }
  &__title {
    margin-bottom: 9px;
    font-size: 18px;
    line-height: 24px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 400;
    text-decoration: underline;
    transition: 0.2s ease-in-out;
  }
  &__location {
    margin-bottom: 9px;
    font-size: 13px;
    line-height: 15px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 400;
    transition: 0.2s ease-in-out;
  }
  &__desc {
    margin-bottom: 11px;
    font-size: 13px;
    line-height: 15px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 400;
    transition: 0.2s ease-in-out;
  }
  &__btn {
    position: relative;
    margin-top: 4px;
    padding: 7px 30px;
    padding-left: 38px;
    padding-right: 25px;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    background: #000b93;
    border-radius: 25px;
    outline: 0;
    border: 1px solid #000b93;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:before {
      position: absolute;
      content: url("../../assets/images/svg/arrow-right.svg");
      width: 14px;
      height: 14px;
      left: 17px;
      top: 6px;
    }
  }
}

.react-datepicker__input-container > input,
.filterInput {
  display: flex;
  align-items: center;
  position: relative;
  height: 35px;
  border: 2px solid #7b97f8;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background: #fff;
  border-radius: 25px;
  text-indent: 6%;
  cursor: pointer;
  font-size: 12px;
  color: #363963;
  font-family: "Arimo";
  font-weight: 700;
  text-transform: uppercase;
  outline: none;
  &::placeholder {
    color: #363963;
  }
}

.react-datepicker__input-container > input {
  text-indent: 11%;
}

.react-datepicker-popper {
  z-index: 16 !important;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 720px) {
    margin-bottom: 25px;
  }
}

.card-name {
  color: #000b93;
}

.pagination {
  position: relative;
  margin-top: 80px;
  font-size: 14px;
  line-height: 18px;
  color: #0817d0;
  font-family: "Arimo";
  font-weight: 700;
  & li {
    & a {
      padding: 15px;
      padding-top: 13px;
      padding-bottom: 13px;
      margin-right: 7px;
      cursor: pointer;
      outline: none;
    }
  }
  & .active a {
    background: rgb(217, 221, 242);
    box-shadow: 0 0 6px rgba(3, 4, 4, 0.11);
  }
  & .next {
  }
  & .disabled {
    display: none;
    color: #8d8db9;
  }
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  height: 300px;
  animation: fadein 0.886s;
  &__cards-list {
    display: flex;
    justify-content: center;
  }
}

.fade-animation {
  animation: fadein 0.886s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cards-wrapper {
  margin-top: 40px;
}

.hight-res-filers {
  display: block;
  @media screen and (max-width: 720px) {
    display: none;
  }
}

.low-res-filers {
  display: none;
  .InformationPage__list-collapse-btn-wrapper {
    border: none;
  }
  @media screen and (max-width: 720px) {
    display: block;
    margin-bottom: -25px;
  }
}

.margin_bottom {
  margin-bottom: 10px;
}
.margin_top {
  margin-top: 10px;
}

.low-res-filter-btn {
  margin-bottom: 20px;
  margin-left: 0;
}
