.ReportPage {
  position: relative;
  grid-template-columns: 9.3% 1fr 9.3%;
  margin: 0 auto;
  padding-top: 87px;
  padding-bottom: 200px;
  max-width: 97%;
  z-index: 4;
  .ReportPageTabs {
    max-width: 1100px;
    margin: 0 auto;
  }
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
    padding-top: 35px;
  }
  &__breadcrumbs {
    position: relative;
    display: grid;
    grid-template-columns: 9.3% 1fr 9.3%;
    max-width: 1400px;
    margin: 0 auto;
    z-index: 4;
    @media screen and (max-width: 720px) {
      grid-template-columns: 4% 1fr 4%;
    }
    &__main-link {
      font-size: 14px;
      color: #b4b4d1;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__second-link {
      display: inline-block;
      font-size: 14px;
      color: #75758d;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__slash {
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      border-right: 1px solid #b4b4d1;
      height: 10px;
      transform: rotate(25deg);
    }
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #fff;
    @media screen and (max-width: 720px) {
      padding-top: 310px;
    }
  }
  &__title {
    margin-bottom: 37px;
    font-size: 52px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
    @media screen and (max-width: 720px) {
      margin-bottom: 41px;
    }
    @media screen and (max-width: 670px) {
      font-size: 40px;
      line-height: 43px;
    }
    @media screen and (max-width: 425px) {
      font-size: 26px;
      line-height: 36px;
    }
  }
  &__preview {
    margin-bottom: 37px;
    margin-top: -15px;
    font-size: 20px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 700;
  }
  &__content {
    &__p {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 22px;
      color: #363963;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__list {
      padding-top: 30px;
      padding-bottom: 30px;
      margin-left: -30px;
      padding-left: 30px;
      margin-right: -30px;
      padding-right: 30px;
      background: #ededfd;
    }
    &__title {
      font-size: 25px;
      color: #000b93;
      font-family: "Arimo";
      font-weight: 700;
    }
    &__item {
      position: relative;
      margin-bottom: 10px;
      list-style-type: none;
      font-size: 16px;
      line-height: 22px;
      color: #363963;
      font-family: "Arimo";
      font-weight: 400;
      &:before {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        left: -17px;
        top: 9px;
        background: #ff9b26;
        border-radius: 50%;
      }
    }
    &__block {
      max-width: calc(100vw - 18.6%);
    }
  }
  .report_field {
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }
  .ui.checkbox {
    margin: 6px 0;
    font-size: 16px;
  }
  .input_label {
    min-width: 125px;
    display: inline-block;
    margin-bottom: 15px;
  }
  .error_field {
    color: red;
  }
  .long_input .ui.input {
    width: 700px;
    max-width: 97%;
  }
  .long_input .ui.input input {
  }
  .ui.tabular.menu .item {
    display: none;
  }
}
