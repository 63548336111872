.Header {
  position: relative;
  z-index: 5;
  display: grid;
  grid-template-columns: 16% 750px 375px 2%;
  font-size: 18px;
  font-weight: 700;
  font-family: Montserrat;
  height: 88px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 2% 1fr 2%;
    padding-bottom: 30px;
    padding-top: 30px;
    grid-row-gap: 0px;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 2% 1fr 2%;
    padding-bottom: 30px;
  }
  &__logo {
    position: relative;
    background: no-repeat url('../../../assets/images/png/logoDPPO.png');
    background-position: center;
    height: 100px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      transition: opacity 0.4s;
    }
  }
  &__navigation {
    display: flex;
    margin-right: 30px;
    justify-content: space-between;
    text-align: center;
    @media screen and (max-width: 1024px) {
      margin-left: -11%;
      display: none;
    }
    @media screen and (max-width: 742px) {
      grid-template-columns: 26% 1fr 1fr;
      margin-left: -3%;
      text-align: center;
    }
    @media screen and (max-width: 695px) {
      display: none;
    }
    @media screen and (max-width: 425px) {
      display: none;
    }
    .about,
    .registry,
    .catalog {
      display: flex;
      align-items: center;
      color: #00148d;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &__login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    align-items: center;
    margin-right: -3.5%;
    outline: none;
    justify-items: center;

    @media screen and (max-width: 1024px) {
      margin-right: 0;
      padding-right: 7px;
      padding-left: 7px;
    }
    @media screen and (max-width: 425px) {
      margin-right: 0;
    }
    .user-icon {
      display: none;
      width: 35px;
    }
    .burger-menu {
      display: none;
      width: 35px;
      justify-self: flex-end;
      z-index: 1501;
      @media screen and (max-width: 1024px) {
        display: block;
      }
      @media screen and (max-width: 695px) {
        display: block;
      }
    }
  }
  &__search {
    display: grid;
    grid-template-columns: 1fr 9.3%;
    position: relative;
    grid-column-start: 2;
    grid-column-end: 5;
    align-items: center;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-column-end: 5;
    }
    @media screen and (max-width: 720px) {
      grid-template-columns: 1fr;
      grid-column-end: 3;
    }
    .categorie_select {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 3fr;
      align-items: center;
      width: 195px;
      height: 100%;
      background-color: #113bc9;
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      text-align: center;
      cursor: pointer;
      @media screen and (max-width: 425px) {
        display: none;
      }
      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 28.5px solid transparent;
        border-left: 15px solid #113bc9;
        border-bottom: 28.5px solid transparent;
        right: -15px;
      }
      .icon-container {
        text-align: right;
        .categorie_select_icon {
          width: 20px;
          margin-top: 1px;
        }
      }
    }
    .search-field {
      width: 100%;
      height: 55px;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      color: #fff;
      border: 0.1px solid #2131b1;
      background-color: #2131b1;
      text-indent: 5%;
      vertical-align: middle;
      outline: none;
    }
    .search-icon {
      position: absolute;
      width: 25px;
      top: 14px;
      right: 135px;
      cursor: pointer;
      @media screen and (max-width: 1024px) {
        right: 20px;
      }
    }
  }
  .registration {
    cursor: pointer;
    color: #00148d;

    @media screen and (max-30pxwidth: 695px) {
      display: none;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .fade-animation {
    animation: fadein 3.886s;
  }
  .enterBtn {
    position: relative;
    @media screen and (max-width: 1024px) {
      text-align: left;
    }
  }
  .popup-link {
    color: #00148d;
  }
  .hide {
    display: none;
  }
  .registration-closed {
    color: #00148d;
  }
  .popup-arror {
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      width: 20px;
      height: 20px;
      background: #67d08c;
      transform: rotate(45deg) translateX(-50%);
      top: 47px;
    }
    @media screen and (max-width: 1024px) {
      &:after {
        left: 3.5%;
      }
    }
  }

  .enter-popup {
    position: absolute;
    top: 0px;
    left: -190px;
    width: 450px;
    margin-top: 50px;
    background: #ffffff;
    padding: 40px 30px;
    text-align: left;
    border-radius: 15px;
    border: 4px solid #67d08c;

    @media screen and (max-width: 1024px) {
      left: -10px;
    }
    &__item {
      position: relative;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #00148d;
      &:hover {
        text-decoration: underline;
      }
      &:nth-child(1) {
        margin-bottom: 25px;
      }
      :after {
        content: '';
        position: absolute;
        right: 0;
        width: 12px;
        height: 12px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        top: 40%;
      }
      & span {
        cursor: pointer;
      }
    }
  }
}

.enter-btn {
  position: relative;
  margin-top: -10px;
  padding: 8px 20px;
  line-height: 18px;
  outline: 0;
  border: 2px solid #dedc68;
  cursor: pointer;

  color: #00148d;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: #dedc68;
  border-radius: 33px;
  &:hover {
    background-color: #e7e692;
  }
}

.user-name {
  color: #00148d;
}

.user-role {
  color: #00148d;
  font-size: 13px;
  opacity: 0.5;
}

.enter-admin-btn,
.exit-btn {
  display: flex;
  justify-content: flex-end;
}

.enter-admin-btn {
  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
  }
}

.registration,
.exit-btn {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.enter-admin-btn a {
  margin-right: -15%;
  margin-top: -4.5%;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-menu-wrapper {
  position: absolute;

  & a {
    text-decoration: none;
  }
}

.bm-overlay {
  background-color: #2131b1 !important;
}

.bm-item-list {
  text-align: left;
  padding: 10px;
  padding-top: 70px;
}

.bm-item {
  margin-bottom: 20px;
  &:last-child {
    margin-top: 35px;
  }
}

.no_m_b {
  margin-top: 0 !important;
}

.bm-item span {
  color: #fff;
  cursor: pointer;
  outline: none;
}
