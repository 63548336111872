.Pagination-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 150px;
  ul {
    padding: 0;
    display: flex;
    list-style: none;
  }
  li {
    a {
      cursor: pointer;
      color: #0818cd;
      padding: 10px 13px;
      border-radius: 2px;
      &:hover {
        background: #f3f4fb;
      }
    }
    &.selected {
      a {
        background: #d8ddf1;
      }
    }
    &.disabled {
      color: #a7a7a7;
      &:hover {
        background: unset;
      }
    }
  }
}
