.CatalogPage {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 137px;
  padding-bottom: 200px;
  z-index: 4;
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #fff;
  }
  &__title {
    margin-bottom: 37px;
    font-size: 52px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
  }
  &__upper-filter {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    align-items: center;
    margin-bottom: 23px;
  }
  &__search-container {
    position: relative;
    display: grid;
    grid-template-columns: 29.5% 1fr;
    height: 100%;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 13px;
    color: #ededfd;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    &:after {
      position: absolute;
      content: url("../../assets/images/svg/musica-searcher-blue.svg");
      width: 25px;
      right: 11px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .burger_icon {
    margin-top: 9px;
    margin-left: 18px;
    width: 25px;
    fill: #ff8500;
  }
  &__search-select {
    position: relative;
    background: #000b93;
    color: #fff;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    &__input {
      padding-right: 45px;
      font-size: 13px;
      color: #363963;
      font-family: "Arimo";
      font-weight: 700;
      text-indent: 2%;
      text-transform: uppercase;
      border: 2px solid #000b93;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      outline: none;
    }
  }
  &__lower-filter {
    display: grid;
    grid-template-columns: 38% 35% 1fr;
    grid-gap: 33px;
    align-items: center;
  }
  &__sort-container {
    position: relative;
    font-size: 14px;
    color: #000000;
    font-family: "Arimo";
  }
  &__sort-category {
    margin-left: 59px;
  }
  &__arrow {
    position: absolute;
    content: "";
    background: url("../../assets/images/png/form-arrow.png");
    width: 15px;
    height: 15px;
    top: 50%;
    transform: translateY(-50%);
    left: 98px;
    background-repeat: no-repeat;
    border: 2px solid #aaa;
    border-radius: 50%;
    padding: 10.1px;
    background-position: center;
    transition: 300ms;
  }
  &__checkbox-container {
    justify-self: flex-end;
  }
}

.CatalogPageCard {
  padding: 25px 40px;
  margin-bottom: 30px;
  box-shadow: 0 0 6px rgba(3, 4, 4, 0.11);
  font-family: "Arimo";
  font-weight: 400;
  transition: 300ms;
  &:hover {
    box-shadow: 0 0 6px rgba(3, 4, 4, 0.41);
  }
  &__title {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 400;
    text-decoration: underline;
  }
  &__desc {
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 18px;
    color: #363963;
  }
  &__lower-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 60px;
  }
  &__org,
  &__format,
  &__time-name {
    display: block;
    font-size: 13px;
    line-height: 18px;
    color: #363963;
    font-weight: 700;
  }
  &__org-name,
  &__format-name {
    display: block;
    font-size: 13px;
    line-height: 18px;
    color: #363963;
  }
  &__org-location {
    display: block;
    font-size: 13px;
    line-height: 18px;
    color: #bfbfe4;
  }
  &__time {
    font-size: 20px;
    line-height: 20px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 700;
  }
  &__btn {
    position: relative;
    margin-top: 4px;
    padding: 7px 30px;
    padding-left: 38px;
    padding-right: 25px;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    background: #000b93;
    border-radius: 25px;
    outline: 0;
    border: 1px solid #000b93;
    cursor: pointer;
    &:before {
      position: absolute;
      content: url("../../assets/images/svg/arrow-right.svg");
      width: 14px;
      height: 14px;
      left: 17px;
      top: 6px;
    }
  }
}
