.News {
  width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 90px;
  &__header {
    margin: 0 auto;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    color: #314190;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 40px;
  }
  &__btn-container {
    display: flex;
    justify-content: flex-end;
  }
  &__btn {
    background: #68cf8c;
    border: 1px solid #68cf8c;
    border-radius: 25px;
    padding: 15px 25px;
    color: #ffffff;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    height: 40px;
    letter-spacing: 0.043em;
    outline: none;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
      background: #66c487;
    }
  }
}
