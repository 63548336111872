.Search-wrapper {
  font-size: 18px;
  max-width: 1000px;
  margin: auto;
  position: relative;
  min-height: 50px;
}

.SearchCard {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #d7d7d7;
  &__count {
    margin-bottom: 1rem;
    font-family: "Montserrat";
  }
  &__title {
    display: block;
    margin-bottom: 0.5rem;
    color: #000;//
    cursor: pointer;
    &:hover {
      text-decoration: underline !important;
    }
  }
  &__category {
    display: inline-block;
    padding: 2px 10px 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3;
    margin-left: auto;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    background: #e0b33a;
  }
  &__content {
    font-weight: 300;
  }
}

.programm_type {
  background-color: #2131b1;
}
