@import url("https://fonts.googleapis.com/css?family=Arimo:400,400i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,700&display=swap");

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Arimo",
    "Montserrat", "Fira Sans Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  overflow-x: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}

img {
  width: 100%;
  height: auto;
}
