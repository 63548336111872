.RecoverPassword {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 137px;
  padding-bottom: 200px;
  z-index: 4;
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #fff;
  }
  &__title {
    margin-bottom: 37px;
    font-size: 52px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
  }
  &__text {
    margin-bottom: 42px;
    font-size: 16px;
    line-height: 22px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 400;
    text-align: center;
  }
  &__form-login {
    max-width: 500px;
    margin: 0 auto;
  }
  .form-container {
    background-color: #ededfd;
  }
  .lower-container-recover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
  }
  .submit-recover {
    position: relative;
    display: block;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    font-family: "Arimo";
    color: #fff;
    background-color: #0817d0;
    border: 1px solid #0817d0;
    border-radius: 25px;
    outline: none;
    transition: 0.3s;
    &:hover {
      background-color: #4c579a;
      border: 1px solid #4c579a;
    }
  }
}
