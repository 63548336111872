.prof-discussion {
  padding: 250px 0 150px;
  position: relative;
  z-index: 9;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.prof-discussion__wrap {
  display: flex;
  flex-direction: column;
}

.prof-discussion__label {
  display: flex;
  flex-direction: column;
  color: #363963;
}

.prof-discussion__input {
  background: #ededfd;
  border: 2px solid #bfbfe4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 14px;
  color: #363963;
  margin-top: 10px;
  margin-bottom: 30px;
}

.prof-discussion__input:focus {
  outline: none;
  border: 2px solid #7777ac;
}

.prof-discussion__checkbox-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.prof-discussion__btn {
  margin: 0 auto;
  display: block;
}

.prof-discussion__title {
  font-weight: bold;
  font-size: 52px;
  color: #000b93;
  margin-bottom: 80px;
}

.prof-discussion__btn {
  background: #000b93;
  border-radius: 25px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  padding: 15px 24px;
  border: none;
  margin-top: 60px;
}

.prof-discussion__btn:hover {
  cursor: pointer;
}

.prof-discussion__btn:active {
  opacity: 0.7;
}

.prof-discussion__btn:disabled {
  opacity: 0.3;
}

.prof-discussion__first_answer {
  color: #363963;
  margin-bottom: 10px;
}

.prof-discussion__checkbox-label {
  display: flex;
  padding-left: 60px;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    top: 5px;
    left: 0px;
    width: 36px;
    height: 36px;
    background-image: url("../../assets/images/svg/check-off.svg");
  }
}

.prof-discussion__checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.prof-discussion__checkbox-input:checked
  + .prof-discussion__checkbox-label::after {
  background-image: url("../../assets/images/svg/check-on.svg");
}

.prof-discussion__checkbox-name {
  display: block;
  background: #ededfd;
  border: 2px solid #bfbfe4;
  border-radius: 20px;
  padding: 14px;
  width: 305px;
  text-align: center;
}
