.FullProgramm {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 87px;
  padding-bottom: 200px;
  z-index: 4;
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
    padding-top: 35px;
  }
  &__breadcrumbs {
    position: relative;
    display: grid;
    grid-template-columns: 9.3% 1fr 9.3%;
    max-width: 1400px;
    margin: 0 auto;
    z-index: 4;
    @media screen and (max-width: 720px) {
      grid-template-columns: 4% 1fr 4%;
    }
    &__main-link {
      font-size: 14px;
      color: #b4b4d1;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__second-link {
      display: inline;
      font-size: 14px;
      color: #75758d;
      font-family: "Arimo";
      font-weight: 400;
    }
    &__slash {
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      border-right: 1px solid #b4b4d1;
      height: 10px;
      transform: rotate(25deg);
    }
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #fff;
    @media screen and (max-width: 720px) {
      padding-top: 310px;
    }
  }
  &__title {
    margin-bottom: 37px;
    font-size: 52px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
    @media screen and (max-width: 720px) {
      margin-bottom: 41px;
    }
    @media screen and (max-width: 670px) {
      font-size: 40px;
      line-height: 43px;
    }
    @media screen and (max-width: 425px) {
      font-size: 26px;
      line-height: 36px;
    }
  }
  &__content {
    .padding {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .border-top {
      border-top: 1px solid #dcdbff;
    }
    &__item-wrapper {
      display: flex;
      align-items: center;
      padding-top: 33px;
      padding-bottom: 33px;
      border-bottom: 1px solid #dcdbff;
      @media screen and (max-width: 720px) {
        display: block;
      }
    }
    &__item-data {
      width: 79%;
      font-size: 16px;
      line-height: 22px;
      color: #363963;
      font-family: "Arimo";
      font-weight: 400;
      @media screen and (max-width: 720px) {
        width: 100%;
      }
    }
    &__item-category {
      width: 18%;
      padding-right: 30px;
      font-size: 11px;
      line-height: 18px;
      color: #8d8db9;
      font-family: "Arimo";
      font-weight: 700;
      text-align: right;
      text-transform: uppercase;
      @media screen and (max-width: 720px) {
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
      }
    }
    &__list {
      padding-top: 30px;
      padding-bottom: 30px;
      margin-left: -30px;
      padding-left: 30px;
      margin-right: -30px;
      padding-right: 30px;
      background: #ededfd;
    }
    &__title {
      font-size: 25px;
      color: #000b93;
      font-family: "Arimo";
      font-weight: 700;
    }
  }
}

.link-agree {
  display: block;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-family: "Arimo";
  color: #fff;
  background-color: #0817d0;
  border: 1px solid #0817d0;
  border-radius: 10px;
  outline: none;
  transition: 0.3s;

  &:hover {
    background-color: #4c579a;
    border: 1px solid #4c579a;
    color: #fff;
  }
}

.link-container {
  margin-right: 10px;
}

.custom-download-wrapper {
  justify-content: flex-end;
}

.bc-width {
  width: 47%;
  @media screen and (max-width: 720px) {
    width: 100%;
  }
}
