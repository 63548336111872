.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: center;
}

.page-item {
  margin-right: 0.5em;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.85rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #6873b5;
  background: #fff;
  font-family: "Roboto Condensed", sans-serif;

  &:hover,
  &:active {
    color: #3d4669;
    background: #d9ddf2;
    cursor: pointer;
  }
}

.page-link-active {
  position: relative;
  display: block;
  padding: 0.5rem 0.85rem;
  margin-left: -1px;
  line-height: 1.25;
  font-family: "Roboto Condensed", sans-serif;

  &:hover,
  &:active {
    color: #3d4669;
    background: #d9ddf2;
    cursor: pointer;
  }
  color: #3d4669;
  background: #d9ddf2;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}
