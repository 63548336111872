.error {
  text-align: center;
  color: #483c9a;
  font-size: 20px;
}
.ArticlePage {
  font-family: "Arimo";
  font-size: 17px;
  color: #363963;
  text-align: justify;
  &__caption {
    margin-bottom: 30px;
    margin-top: 40px;
    font-size: 22px;
    color: #000b93;
    font-weight: 700;
  }
  &__button {
    padding: 8px 35px;
    background: transparent;
    border: 2px solid #0010e2;
    border-radius: 20px;
    color: #0010e2;
    cursor: pointer;
  }
  ul {
    list-style: none;
    li {
      position: relative;
      padding: 5px 0;
      &:before {
        content: "•";
        position: absolute;
        top: 0;
        left: -18px;
        color: #ffba12;
        font-size: 24px;
      }
    }
  }
  img {
    width: 100%;
    padding: 20px 0;
  }
  .button-wrapper {
    margin: 50px 0;
    text-align: center;
  }
}
